import React, { useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Stack,
    ListItemIcon,
    ListItemText,
    List,
    ListItem,
    TextField,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ShareIcon from '@mui/icons-material/Share';
import SendIcon from '@mui/icons-material/Send';
import { ISharedCostEstimation } from '../../services/public/types';
import pageTheme from '../../theme';

export const SharingOptionsDialog = ({
    handleGeneralShare,
    setSharingDialogPage,
}: {
    handleGeneralShare: () => void;
    setSharingDialogPage: (
        page: 'initial_email' | 'test_email' | 'option_menu' | undefined
    ) => void;
}) => {
    return (
        <List>
            <ListItem
                onClick={() => {
                    setSharingDialogPage('initial_email');
                }}
                sx={{
                    'borderRadius': '8px',
                    'mb': 1,
                    'cursor': 'pointer',
                    '&:hover': {
                        'bgcolor': pageTheme.colors.primary400,
                        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                            color: 'white',
                        },
                    },
                }}
            >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <EmailIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Email"
                    primaryTypographyProps={{
                        sx: { fontWeight: 500 },
                    }}
                />
            </ListItem>
            <ListItem
                onClick={() => {
                    handleGeneralShare();
                    setSharingDialogPage('option_menu');
                }}
                sx={{
                    'borderRadius': '8px',
                    'cursor': 'pointer',
                    '&:hover': {
                        'bgcolor': pageTheme.colors.primary400,
                        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                            color: 'white',
                        },
                    },
                }}
            >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <ShareIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Other sharing options"
                    primaryTypographyProps={{
                        sx: { fontWeight: 500 },
                    }}
                />
            </ListItem>
        </List>
    );
};

export const SendTestEmailDialog = ({
    setSharingDialogPage,
    testEmailAddress,
    setTestEmailAddress,
    setIsTestEmail,
    handleEmailShare,
}: {
    setSharingDialogPage: (
        page: 'initial_email' | 'test_email' | 'option_menu' | undefined
    ) => void;
    testEmailAddress: string;
    setTestEmailAddress: (testEmailAddress: string) => void;
    setIsTestEmail: (isTestEmail: boolean) => void;
    handleEmailShare: () => void;
}) => {
    if (testEmailAddress.trim() !== '') {
        setIsTestEmail(true);
    }
    return (
        <Box sx={{ p: 2, width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: 2,
                    pb: 1,
                    borderBottom: '1px solid #E5E5E5',
                }}
            >
                <Box
                    sx={{
                        bgcolor: pageTheme.colors.primary400,
                        borderRadius: '5px',
                        width: { xs: 32, sm: 40 },
                        height: { xs: 32, sm: 40 },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: 1,
                    }}
                >
                    <EmailIcon
                        sx={{
                            color: '#fff',
                            fontSize: { xs: 18, sm: 24 },
                        }}
                    />
                </Box>
                <Typography
                    sx={{
                        fontSize: {
                            xs: '1.1rem',
                            sm: '1.4rem',
                        },
                        fontWeight: 700,
                        color: '#000',
                    }}
                >
                    Send Test Email
                </Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography
                    sx={{
                        fontSize: '0.875rem',
                        color: '#4A4A4A',
                        mb: 2,
                    }}
                >
                    Send a test version of this email to preview before sending
                    to the client.
                </Typography>

                <Typography sx={{ fontWeight: 500, mb: 1 }}>
                    Test Email Address
                </Typography>
                <TextField
                    fullWidth
                    onChange={(e) => setTestEmailAddress(e.target.value)}
                    placeholder="Enter test email address"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            'borderRadius': 2,
                            'borderColor': '#D0D0D0',
                            '&.Mui-focused fieldset': {
                                borderColor: '#D0D0D0',
                                borderWidth: '1px',
                            },
                            '&:hover fieldset': {
                                borderColor: '#D0D0D0',
                            },
                        },
                        'mb': 2,
                    }}
                />
                <Typography
                    variant="caption"
                    sx={{
                        color: '#666',
                        display: 'block',
                        fontSize: '0.75rem',
                    }}
                >
                    Enter a test email address to receive a test version before
                    sending to the client.
                </Typography>
            </Box>

            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setSharingDialogPage(undefined);
                    }}
                    sx={{
                        'color': '#666',
                        'fontWeight': 500,
                        'py': 1,
                        'fontSize': '0.875rem',
                        'width': { xs: '30%', sm: '50%' },
                        'textTransform': 'none',
                        'border': '1px solid #E5E5E5',
                        'borderRadius': '8px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            border: '1px solid #E5E5E5',
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    startIcon={<EmailOutlinedIcon />}
                    onClick={() => {
                        handleEmailShare();
                    }}
                    sx={{
                        'bgcolor': pageTheme.colors.primary400,
                        'fontWeight': 500,
                        'py': 1,
                        'fontSize': '0.875rem',
                        'width': { xs: '70%', sm: '50%' },
                        'textTransform': 'none',
                        'borderRadius': '8px',
                        'boxShadow': 'none',
                        '&:hover': {
                            bgcolor: pageTheme.colors.primary400Light,
                            boxShadow: 'none',
                        },
                    }}
                >
                    Send Test Email
                </Button>
            </Stack>
        </Box>
    );
};

export const ConfirmEmailDeliveryDialog = ({
    estimateData,
    handleEmailShare,
    setSharingDialogPage,
    emailSubject,
    emailBody,
    setEmailSubject,
    setEmailBody,
}: {
    estimateData: ISharedCostEstimation;
    handleEmailShare: () => void;
    setSharingDialogPage: (
        page: 'initial_email' | 'test_email' | 'option_menu' | undefined
    ) => void;
    emailSubject: string;
    emailBody: string;
    setEmailSubject: (subject: string) => void;
    setEmailBody: (body: string) => void;
}) => {
    const DEFAULT_EMAIL_SUBJECT = `[YOUR BUSINESS NAME HERE] sent you an estimate`;
    const DEFAULT_EMAIL_BODY = `[YOUR BUSINESS NAME HERE] has prepared a detailed estimate for you to review for your [PROJECT NAME HERE]. You can review all the details and approve this estimate by clicking the link below.

Sincerely,
[YOUR BUSINESS NAME HERE]`;

    // Set default values if they don't exist on estimateData
    useEffect(() => {
        if (!emailSubject && !emailBody) {
            setEmailSubject(
                estimateData?.metadata?.email_info?.email_subject ||
                    DEFAULT_EMAIL_SUBJECT.replace(
                        '[YOUR BUSINESS NAME HERE]',
                        estimateData?.metadata?.business_info?.business_name ||
                            ''
                    )
            );
            setEmailBody(
                estimateData?.metadata?.email_info?.email_body ||
                    DEFAULT_EMAIL_BODY.replaceAll(
                        '[YOUR BUSINESS NAME HERE]',
                        estimateData?.metadata?.business_info?.business_name ||
                            ''
                    ).replace(
                        '[PROJECT NAME HERE]',
                        estimateData?.estimate.merchant || 'Project'
                    )
            );
        }
    }, []);

    return (
        <Box sx={{ p: 2, width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: 2,
                    pb: 1,
                    borderBottom: '1px solid #E5E5E5',
                }}
            >
                <Box
                    sx={{
                        bgcolor: pageTheme.colors.primary400,
                        borderRadius: '5px',
                        width: { xs: 32, sm: 40 },
                        height: { xs: 32, sm: 40 },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: 1,
                    }}
                >
                    <EmailIcon
                        sx={{
                            color: '#fff',
                            fontSize: { xs: 18, sm: 24 },
                        }}
                    />
                </Box>
                <Typography
                    sx={{
                        fontSize: {
                            xs: '1.1rem',
                            sm: '1.4rem',
                        },
                        fontWeight: 700,
                        color: '#000',
                    }}
                >
                    Confirm Email Delivery
                </Typography>
            </Box>
            <Box
                sx={{
                    mb: 2,
                    gap: 3,
                    pt: 2,
                    pb: 0,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        border: '1px solid rgb(187, 246, 250)',
                        borderRadius: 2,
                        bgcolor: '#ecfeff',
                    }}
                    py={2}
                    px={{ xs: 1, sm: 2 }}
                >
                    <Typography
                        sx={{
                            color: '#4A4A4A',
                            fontSize: {
                                xs: '1rem',
                                sm: '1.1rem',
                            },
                            mb: 1,
                            lineHeight: 1.6,
                        }}
                    >
                        This estimate will be sent to{' '}
                        <Typography
                            sx={{
                                color: pageTheme.colors.primary400,
                                fontWeight: 700,
                                display: 'inline',
                                fontSize: { xs: '1rem', sm: '1.1rem' },
                            }}
                        >
                            {estimateData?.metadata.client_info?.client_email}
                        </Typography>
                        <br />
                    </Typography>
                    <Typography
                        sx={{
                            color: '#666',
                            fontSize: {
                                xs: '0.9rem',
                                sm: '1rem',
                            },
                        }}
                    >
                        You will be CC'd on this email.
                    </Typography>
                </Box>
            </Box>
            <Box
                borderBottom="1px solid #E5E5E5"
                paddingBottom={4}
                marginBottom={2}
                display="flex"
                flexDirection="column"
            >
                <Typography sx={{ fontWeight: 500 }}>Email Subject </Typography>
                <TextField
                    fullWidth
                    value={emailSubject}
                    placeholder="Enter email subject"
                    onChange={(e) => setEmailSubject(e.target.value)}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            'borderRadius': 2,
                            '&.Mui-focused fieldset': {
                                borderColor: '#969696',
                                borderWidth: '1px',
                            },
                        },
                        'mb': 2,
                    }}
                />
                <Typography sx={{ fontWeight: 500 }}>Email Body </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={5}
                    placeholder="Enter email body"
                    value={emailBody}
                    onChange={(e) => setEmailBody(e.target.value)}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            'borderRadius': 2,
                            '&.Mui-focused fieldset': {
                                borderColor: '#969696',
                                borderWidth: '1px',
                            },
                        },
                        '& .MuiInputBase-input': {
                            lineHeight: 1.6,
                        },
                    }}
                />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                    mb: 2,
                }}
            >
                <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<EmailOutlinedIcon />}
                    onClick={() => setSharingDialogPage('test_email')}
                    sx={{
                        'color': pageTheme.colors.primary400,
                        'fontWeight': 500,
                        'py': 1,
                        'fontSize': '0.875rem',
                        'textTransform': 'none',
                        'border': `1px solid ${pageTheme.colors.primary400}`,
                        'borderRadius': '8px',
                        '&:hover': {
                            border: `1px solid ${pageTheme.colors.primary400}`,
                            backgroundColor: 'rgba(0, 42, 60, 0.04)',
                        },
                    }}
                >
                    Send Test Email
                </Button>
                <Typography
                    variant="caption"
                    sx={{
                        color: '#666',
                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                    }}
                >
                    Preview this email before sending to client
                </Typography>
            </Box>

            <Stack direction="row" spacing={2} justifyContent="space-between">
                <Button
                    onClick={() => {
                        setSharingDialogPage(undefined);
                    }}
                    variant="outlined"
                    sx={{
                        'color': '#666',
                        'fontWeight': 500,
                        'px': { xs: 1, sm: 4 },
                        'py': { xs: 0, sm: 1.3 },
                        'fontSize': {
                            xs: '0.9rem',
                            sm: '1rem',
                        },
                        'width': { xs: '30%', sm: '50%' },
                        'textTransform': 'none',
                        'border': '1px solid #E5E5E5',
                        'borderRadius': '10px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            border: '1px solid #E5E5E5',
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleEmailShare();
                    }}
                    variant="contained"
                    endIcon={<SendIcon sx={{ ml: 1 }} />}
                    sx={{
                        'bgcolor': pageTheme.colors.primary400,
                        'fontWeight': 500,
                        'px': { xs: 1, sm: 4 },
                        'py': { xs: 1, sm: 1.3 },
                        'borderRadius': '10px',
                        'textTransform': 'none',
                        'fontSize': {
                            xs: '0.9rem',
                            sm: '1rem',
                        },
                        'width': { xs: '70%', sm: '50%' },
                        'boxShadow': 'none',
                        '&:hover': {
                            bgcolor: pageTheme.colors.primary400Light,
                            boxShadow: 'none',
                        },
                    }}
                >
                    Send Estimate
                </Button>
            </Stack>
        </Box>
    );
};

export const AddEmailDialog = ({ handleEdit }: { handleEdit: () => void }) => {
    return (
        <Box
            sx={{
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                alignItems: 'center',
                maxWidth: '400px',
                margin: '0 auto',
            }}
        >
            <Typography
                sx={{
                    color: '#4A4A4A',
                    fontSize: {
                        xs: '1.1rem',
                        sm: '1.4rem',
                    },
                    mb: 1,
                    lineHeight: 1.6,
                }}
            >
                To send this estimate, please provide both your{' '}
                <span
                    style={{
                        color: pageTheme.colors.primary400,
                        fontWeight: 600,
                        display: 'inline',
                    }}
                >
                    Business Email and Client Email
                </span>
                . <br />
                We'll send a copy to you and your client.
            </Typography>
            <Button
                onClick={handleEdit}
                variant="contained"
                sx={{
                    'bgcolor': pageTheme.colors.primary400,
                    'fontWeight': 600,
                    'px': { xs: 2, sm: 4 },
                    'py': { xs: 1, sm: 1.5 },
                    'borderRadius': '10px',
                    'textTransform': 'none',
                    'fontSize': { xs: '0.9rem', sm: '1.1rem' },
                    'boxShadow': 'none',
                    '&:hover': {
                        bgcolor: pageTheme.colors.primary400Light,
                        boxShadow: 'none',
                    },
                    'gap': 2,
                }}
            >
                <EmailIcon /> Add Email
            </Button>
        </Box>
    );
};
